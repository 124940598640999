$breakpoints: ('xs-phone': 320px,
        'phone': 480px,
        'tablet': 768px,
        'desktop': 1024px,
        'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
        'print': 'print',
        'handheld': 'handheld',
        'landscape': '(orientation: landscape)',
        'portrait': '(orientation: portrait)',
        'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
        'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';

#root{
    --animation-speed : 0.3s;
}

body,
p{
    margin: 0;
}

ul{
    @extend %list-reset;
}

html, body, #root{
    min-height: 100vh;
    height: 100%;
}

.app{
    display: flex;
    height: 100%;
}

body{}

svg {
    tspan,
    text{
        font-family: sans-serif
    }
}

.row{
    display: flex;

    & > * {
        width: 100%;

        &:first-child{
            margin-right: 5px;
        }

        & + &{
            margin-left: 5px;
        }
    }
}

.ant-form{
    &-item{
        margin-bottom: 10px;

        &-label{
            padding-bottom: 0 !important;
        }
    }
}

.pre-upload-image{
    display: flex;
    justify-content: space-between;

    .img-name{
        align-items: center;
        max-width: calc(100% - 60px);
        display: flex;

        span{
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            width: 100%;
        }
    }
}

