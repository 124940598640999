#svg-playground{
    width: 100%;
    height: 100%;

    rect,
    text{
        transition: stroke, stroke-width var(--animation-speed) ease-in-out;
    }
    &.selection{
        .wrap{
            cursor: pointer;
        }
    }

    .editable{
        stroke: #E84A4D;
        stroke-width: 1px;
        stroke-dasharray: 1px;
    }

    .selected{
        stroke: #44D13F;
        stroke-width: 1px;
        stroke-dasharray: 1px;

        & > *{
            stroke: #44D13F;
            stroke-width: 1px;
            stroke-dasharray: 1px;
        }
    }
}