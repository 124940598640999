.tools-sidebar{
    box-shadow: 0 0 4px 1px rgba(0,0,0, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.6);
    width: 300px;
    background-color: rgba(0, 0, 0, 0.02);
    position: relative;

    .ant-collapse-borderless{
        width: inherit;
        background-color: transparent !important;
    }

    .pre-upload-image{
        margin: 5px 0;
    }

    &-actions{
        position: absolute;
        bottom: 0;
        padding: 20px 15px;
        width: calc(100% - 30px);

        button{
            width: 100%;
        }
    }
}